.pagination {
    display: flex;
    list-style: none;
    padding: 5px 0;
    justify-content: flex-start;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
}


.pagination li a {
    cursor: pointer;
    padding: 8px 16px;
    border-radius: 5px;
    background-color: #f3f3f3;
    color: #000;
    text-decoration: none;
    margin-left: 3px;
    margin-right: 3px;
}

.pagination li a:hover {
    background-color: #e6e3e3;
}

.active a {
    border-radius: 8px !important;
    background-color: #454f5b !important;
    color: white !important;
}

.break-me {
    cursor: default;
}

@media only screen and (max-width: 768px) {
    .pagination li a {
        padding: 6px 12px !important;
        font-weight: normal;
    }
}